.toast-container {
	position: absolute;
	top: 4.5rem;
	right: 1rem;
}

.toast {
	background-color: rgba(255, 255, 255, 1.0) !important;
	width: 315px;

	.toast-body {
		background-color: #DDEBC6;
		height: 209px;
		font-size: 1rem;
	}

	.toast-footer {
		min-height: 109px;
		padding: 1em;

		.btn {					
			max-height: 38px;		
		}

		.btn-danger {
			width: 71px;
		}
	}

	.toast-header {
		background: rgba(74, 74, 74, 1);
		color: rgb(255, 255, 250);
		min-height: 49px; 
		justify-content: center;
	}
}


.toast .btn-answer.btn-success:not(:disabled):not(.disabled):active,
.toast .btn-answer.btn-success:hover {
	color: #000000;
}

.toast-animate {
		background: rgba(61, 168, 23, 1) 0% 0% no-repeat padding-box;
		border-radius: 50%;
		padding: 15px;
		animation: pulse 2s infinite;
}

@keyframes pulse {
	0% {
		transform: scale(0.9);
		box-shadow: 0 0 0 0 rgba(61, 168, 23, 0.7);
	}

	30% {
		transform: scale(1);
		box-shadow: 0 0 0 20px  rgba(61, 168, 23, 0.4);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 20px  rgba(61, 168, 23, 0);
	}

	100% {
		transform: scale(0.9);
	}
}
