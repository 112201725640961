.call-tree-team-member {
	display: flex;
	flex-direction: row;
	color: #000000;
	background: #E8E8E8;
	align-items: center;
	height: 2.375rem;
	padding-left: 1rem;
	padding-right: 1rem;
}

.call-tree-team-members div:nth-child(2n) {
	background: #D1D1D1;
}

.call-tree-team-member-name {
	font-size: 13px;
	flex: 1 1 auto;
}

.status-icon {
	margin-right: 1rem;
}

.status-available {
	color: #2CC455;
}

.status-unavailable {
	color: #E5C708;
}