$error-color: #B11818;

.alert-danger {
    background-color: #FFF !important;
    border: solid 1px;
    border-color: $error-color !important;
    color: $error-color !important;

    ul {
        list-style: none;
    }
}