.video-card {
	max-width: 100%;
	max-height: 100%;
	flex: 1 1 auto;
	border-radius: 0.5em;
}

.video-card-local-container {
	position: absolute;
	top: 1em;
	left: 1em;
	z-index: 1000;
	max-width: 20vw;
	max-height: 20vh;
}

.video-card-remote-container {
	width: 100%;
	height: 100%;
	position: relative;
	display: flex;
}

.video-card-local-container .video-card {
	box-shadow: 0.5em 0.5em 0.5em 0px rgba(0,0,0,0.5);
}
