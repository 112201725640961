.select-input.form-control {
    padding: 0.375rem 0.75rem 0.375rem 1.5rem;
}

.select-input-icon-container {
    width: 0;
    height: 0;
}

.select-input-icon-container .select-input-icon {
    top: calc(0.25em + 0.375rem + 1px);
    left: 0.25em;
    position: relative;
    display: block;
}