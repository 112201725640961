.layout-login {
	background-image: image('/img/doctor-tablet_web.jpg') !important;
	background-size: cover;
	min-height: 100%;
	height: 100%;
	z-index: 9999;
	max-width: 100vw !important;

	.layout-container {
		background-color: transparent;
		height: 100%;
	}

	.container {
		margin: 0;
	}

	form {
		background-color: white;
		box-shadow: 4px 3px 10px #00000030;
		margin-left: 10rem;
		width: 579px;

		.btn {
			height: 36px;
			width: 248px;
		}
	}
}

//Used to make the input groups the same size as the font awesome icon for the lock is slightly smaller than the envelope icon
.fw-16 {
	width: 16px;
}

.navbar {
	display: none;
}