.room-header {
    background-color: #335A7D;
    height: 3.4375em;
    position: relative;
    display: flex;
    color: #ffffff;
}

.room-header .btn {
    color: #ffffff;
    padding: 0;
    margin-right: 1.5em;
}

.room-header .btn.leave-room-btn {
    padding-left: 1em;
    padding-right: 1em;
}

.room-header .btn:focus, .room-header .btn.focus {
    box-shadow: none;
}

.room-header .btn:hover {
    color: #ffffff;
}

.room-header-spacer {
    flex: 1 1 auto;
}

.room-header-divider {
    height: 100%;
    width: 1px;
    background-color: #ffffff;
    margin-right: 1.5em;
}