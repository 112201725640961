.call-container {
	margin-top: 2.5rem;
}

.elite-container {
	position: relative;
	width: 100%;
	flex: 0 0 100%;
	padding-bottom: 4px;
	border: 4px solid #246EA7;
}

.elite-container-header {
	background: #246EA7;
    color: #ffffff;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 2;
	border-bottom-right-radius: 4em;
	display: inline-block;
}

.elite-container-contents {
	margin-left: 1rem;
	margin-right: 1rem;
}

.elite-container .btn {
	background: transparent linear-gradient(180deg, #FFFFFF 0%, #DBDBDB 100%) 0% 0% no-repeat padding-box;
    border: 1px solid #C7C7C7;
    border-radius: 3px;
	padding: 0rem 0.5rem;
	font-size: 12px;
}

.elite-container .btn.btn-disabled {
	background: #ACACAC;
	border: none;
}

.elite-container .btn .fa-phone {
	margin-right: .25rem;
}