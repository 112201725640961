.layout-room {
	color: #000000;
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAPAQMAAADTSA1RAAAABlBMVEUwMDBFRUUN/r8xAAAAD0lEQVQImWOY5MkABySyAZSEBEjjfCSMAAAAAElFTkSuQmCC);
}

.layout-room.container-fluid {
	padding: 0;
	max-width: 100%;
	height: 100%;
}

.layout-room .layout-container.container {
	width: 100%;
	max-width: 100%;
	padding: 0;
	height: 100vh;
}

.layout-room .layout-header {
	display: none;
}

.room {
	height: 100%;
	position: relative;
	width: 100vw;
}

.connecting-message-container {
	position: absolute;
	top: calc(50% - 1.5em);
	font-size: 3em;
	color: white;
	font-weight: 600;
	text-align: center;
	width: 100vw;
}

.room-slider {
    height: calc(100% - 3.4375em);
    width: 0;
    min-width: 0;
    transition: min-width 0.2s ease,width 0.2s ease;
    position: fixed;
    right: 0;
    top: 3.4375em;
    background-color: white;
	z-index: 1001;
}

.room-slider.show-slider {
    width: 20%;
    min-width: 20em;
}
