.call-tree-team-header {
	display: flex;
	flex-direction: row;
	color: #ffffff;
	background: transparent linear-gradient(180deg, #42A3EE 0%, #246191 100%) 0% 0% no-repeat padding-box;
	align-items: center;
	height: 2.375rem;
}

.call-tree-team-header.expanded {
	background: #185584;
}

.call-tree-team-title {
	flex: 1 1 auto;
	padding-left: 1rem;
	margin: 0;
}

.call-tree-team-members {
	padding-left: 1rem;
	padding-right: 1rem;
}

.call-tree-team-members-spacer {
	height: 2px;
}

.expando-icon {
	margin-left: 1rem;
	margin-right: 1rem;
}