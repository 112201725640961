@import './CallCenter.scss';

.btn-call-center.btn-decline-call {
    background-color: #B11818;
    border-radius: 5px;
    color: white;
    max-width: 26px;
    width: 100%;
    padding: 2px 0;
    
    i {
        margin: 0 8px;
     }
}