.room-chat {
	display: flex;
    flex: 1 1 auto;
    flex-direction: column;
	color: #000000;
	background: #ffffff;
	width: 100%;
	height: 100%;
}

.room-chat .btn {
	color: #000000;
	margin-top: 1em;
	margin-bottom: 1em;
}

.room-chat-messages {
	flex: 1 1 auto;
	padding-left: 1em;
	padding-right: 1em;
	padding-top: 1em;
	overflow-y: auto;
}

.room-chat-message-inner {
	display: inline-block;
	max-width: 80%;
}

.room-chat-message-right {
	text-align: right;
}

.room-chat-message-right .room-chat-message-inner {
	text-align: left;
}

.room-chat-message-right .room-chat-message-inner {
	background: #A1BCD6;
	color: #1A2F71;
}

.room-chat-message-left .room-chat-message-inner {
	background: #DFDFDF;
	color: #000000;
}

.room-chat-username {
	font-size: .8em;
}

.room-chat-message-inner {
	padding: 0.5em;
	border-radius: 0.5em;
	margin-bottom: 0.5em;
}

.room-chat-message-composer textarea {
	width: 100%;
}
