.layout-home {
	.layout-container {
		background-color: transparent;
	}	
}
.card.dashboard {
	min-height: 403px;
	border-radius: 5px;
}

.card.dashboard h2 {
	font-size:24px;
}

.card.dashboard p {
	margin-bottom: 0px;
}